.sidebar::-webkit-scrollbar {
    width: 5px;
}
   
.sidebar::-webkit-scrollbar-track {
    background-color: #1f2937;
}
   
.sidebar::-webkit-scrollbar-thumb {
    background-color: #4b5563;
}
.sm\:w-1\/5 {
    width: 20%;
   
}

.sm\:z-0 {
    z-index: 0;
   
}
@media screen and (max-width: 1400px) {
    .sm\:w-1\/5 {
        width: 300px;
       
    }
  }