@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f1f3f6;
    font-family: 'Roboto', sans-serif;
}

/* hover navbar dropdown arrow */
.arrow_down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}
/* hover navbar dropdown arrow */

/* login signup sidebar bg image */
.loginSidebar {
    
    background-position: center 85%;
    background-repeat: no-repeat;
}
.bg-primary-dark{
  --tw-bg-opacity: 1;
    background-color: rgb(12 21 36 / var(--tw-bg-opacity));
}
/* login signup sidebar bg image */